/** @format */

const menuBtn = document.getElementById('menu-btn');
const closeBtn = document.getElementById('menu-close-btn');
const nav = document.getElementById('menu');

menuBtn.addEventListener('click', () => {
	menuBtn.classList.toggle('hidden');
	closeBtn.classList.toggle('hidden');
	nav.classList.toggle('translate-x-0');
	nav.classList.toggle('bg-opacity-90');
});

closeBtn.addEventListener('click', () => {
	menuBtn.classList.toggle('hidden');
	closeBtn.classList.toggle('hidden');
	nav.classList.toggle('translate-x-0');
	nav.classList.toggle('bg-opacity-90');
});

const header = document.getElementById('nav');
const sticky = 0;

// window.onscroll = function () {
// 	myFunction();
// };

function myFunction() {
	console.log('scroll');
	if (window.scrollY > sticky) {
		header.classList.add('shadow-md');
	} else {
		header.classList.remove('shadow-md');
	}
}

let throttleTimer;

const throttle = (callback, time) => {
	if (throttleTimer) return;
	throttleTimer = true;
	setTimeout(() => {
		callback();
		throttleTimer = false;
	}, time);
};

window.addEventListener('scroll', () => {
	throttle(myFunction, 250);
});
